@import "./components/styles/navbar.css";
@import "./components/styles/tools.css";
@import "./components/styles/quotes.css";
@import "./components/styles/hero.css";
@import "./components/styles/contact.css";
@import "./components/styles/about.css";
@import "./components/styles/mobile-nav.css";
@import "./components/styles/projects.css";
@import "./components/styles/education.css";


.App{
  cursor: url('https://raw.githubusercontent.com/yashkc2025/yashkc2025.github.io/main/assets/cursor.png'),auto;
}
.mobile-only {
  display: none;
  /* text-align: center; */
  color: white;
}
body {
  /* background-color: #141617; */
  background-color: #060117;
  padding: 2rem;

}
.footer {
  margin-top: 30px;
  text-align: center;
  color: rgb(255, 0, 255);
  font-family: "Comforter Brush";
  font-size: 200%
}
/* .App{
  max-width: 100vw;
} */

hr{

  width: 10vw;
  border-top: 1px solid rgb(162, 255, 0);
  border-bottom: 1px solid rgb(255, 145, 0);
}

@import "./components/styles/media-queries.css";
@import "./languages.css";

.tool-combo {
  border-radius: 2.5rem;
  display: flex;
  /* flex-grow: 2; */
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  min-width: 370px;


  /* min-height:350px; */
  backdrop-filter: blur(20px);
  /* max-width:1800px; */
}

.tool-drop {
  min-width: 370px;

  border-radius: 40px;
  /* background-image: radial-gradient(black, rgb(31, 29, 29), rgb(40, 36, 36)); */
  margin: auto;
  text-align: center;
  margin : 100px 0;
  font-family: 'Inter Tight';
  /* max-width:1800px; */
}

.tool {
  flex-basis: 15%;
  padding: 1rem;
}

.tool > img {
  height: 60px;
  margin-bottom: 0;
}

.tool > h3 {
  margin-top: 0;
  /* -webkit-text-stroke: 0.2px #000; */
}

.tool-drop:hover, .tool-drop:active {
  background-image: none;
  border-style:dotted;
  border-width: 3px;
  border-color:chartreuse chartreuse yellow yellow;
}
.tool-combo:hover > div, .tool-combo:active > div {
  filter: blur(3px) grayscale(100%) brightness(120%);
}

.tool-combo:hover > div:hover, .tool-combo:active > div:hover {
  filter: blur(0px) brightness(120%);
}

.mobile-nav-bar {
  /* position: fixed;
  margin-bottom: -10px;
  margin-top: -30px; */
  display: none;
}

#menuToggle {
  display: block;
  position: relative;
  top: 0px;
  left: -20px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 10px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #ff0000;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  /* transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), */
  /* background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease; */
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: chartreuse;
  width: 55px;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 100vw;
  margin: -100px 0 0 -50px;
  /* padding: 50px; */
  padding-top: 125px;

  /* background: #ededed; */
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  text-align: center;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0px;
  font-size: 22px;
  color: #00ffd5;
  /* filter: blur(100%); */
  background-color: #060117;
  font-family: "Inter Tight";
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}

#menu li:hover {
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-line: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: chartreuse;
  filter: brightness(200%);
  font-size: 150%;
}

/* .mobile-nav-base{
    background-color: black;
    filter:blur(100%)
} */

.projects-head {
  font-family: "DM Serif Display", serif;
  color: chartreuse;
  text-decoration-line: overline;
  text-decoration-style: wavy;
  text-decoration-color: #00fbd6;
  font-size: 200%;
  text-align: center;

}

.pro-image {
  width: 350px;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-position: 100% 0;
}


.pro-text h1 {
  /* text-align: center; */
  padding: 0 1rem;

  font-family: "Domine", serif;
  color: #00ffaa;
  font-weight: 300;
}
.pro-text h3 {
  /* padding: 1rem; */
  text-align: start;
  padding: 0 1rem;
  font-family: "Inter Tight", serif;
  font-weight: 300;
}

.pro-text button {
  height: 40px;

  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 1rem;
  border-radius: 10px;
  border-style: solid;
  border-width: 0.5px;
  background-color: #282c34;
  padding: 0 10px;
}
.pro-text a {
  text-decoration: none;
  /* text-align: start; */
  color: springgreen;
}

.pro-item {
  text-align: start;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10px;
  width: 350px;
  margin-top: 50px;
  background-color: #282c34;
  
}
.projects-view {
  /* background-color: #00fbd6; */
  color: white;
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -3rem; */
  justify-content: center;
  /* justify-content: space-around; */
  /* overflow-x:scroll;
  overflow-y: hidden; */
}

.projects-view:hover > div {
  filter: blur(20px);
}

.projects-view:hover > div:hover {
  filter: blur(0px);
}

faltu{
  display:none;
}

/* .card-lang-img{
  width:30px;
  padding:5px
} */

.card-lang-img{
  color:#02ffaa;
  display: inline-block;
  padding: 0 5px;
  /* font-weight: bold; */
  font-family: "Inter Tight", serif;
}
.pro-text div{
  padding-bottom: 10px;
  text-align: start;
  padding:0 1rem;
}
.edu {
  color:white;
  padding: 0 15rem;
  font-family: "Domine";
}

.edu h1 {
  text-align: center;
  font-family: "DM Serif Display", serif;
  color:yellow;
  text-decoration-line: overline;
  text-decoration-style: wavy;
  text-decoration-color: #00fbd6;
}

.flex-college {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 5rem;
  margin-top:5rem;
}

.college div {
  padding-left: 5rem;
}

.qual{
  width:50vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.iitm-logo{
  height: 7rem;
}
.cbse-logo{
  height: 8rem;
}
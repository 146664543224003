.azure{
  color:#43e6ff;
}

.javascript,
.python {
  color: #ffe873;
}

.css {
  color: white;
}

.java,
.html {
  color: tomato;
}

.dart,
.arduino {
  color: #29fdec;
}

.flutter,
.reactjs {
  color: #5bffff;
}

.arduino {
  color: #00b5bb;
}

.git{
  color:#e84d31;
}

.bulma{
  color:#00fbd6;
}

.figma{
  color: #c26bff;
}

.fa-linkedin{
  color:#0e76a8;
}

.fa-github-alt, .fa-angellist{
  color:white
}

.fa-telegram{
  color:#0088cc;
  
}

.fa-envelope{
  color:yellow;
}
.contact{
    margin:100px 0;
    display: block;
    text-align: center;;
    /* background-color: aliceblue; */
    align-items: center;
}

.contact img{
    width:1.4rem;
}
.contact a{
    /* color: white; */
    padding:1rem;
    font-size: xx-large;
    text-decoration: none;
}

.contact a:hover {
    font-size: 300%;
}

.contact img:hover{
    width:2.5rem;
}

.contact-form{
    display: inline;
    margin-left:2rem;
}

.contact-form button{
    /* font-size: calc(xx-large - 2rem); */
    background-color: chartreuse;
    border-radius: 10px;
    border-style: none;
}

.contact-form a{
    font-size:x-large;
    font-family: "Inter Tight";
    color: black;
}

.social{
    display: inline-block;
}
@media only screen and (max-width: 750px) {
  .right,
  .right-quote-text {
    display: none;
  }


  /* body{
      background-color: white;
    } */
}

@media only screen and (max-width:1200px){
  .edu{
    padding:0;
  }
  .flex-college{
    justify-content: center;
  }
}
@media only screen and (max-width: 870px) {
  .hiding {
    display: none;
  }
  .about-sub {
    padding: 0px;
  }

  .about-section {
    /* padding-left:0px; */
    padding: 0 0 0 20px;
  }


}

@media only screen and (max-width: 680px) {
  .projects-view:hover > div {
    filter:none
  }
  
  .projects-view:hover > div:hover {
    filter: none
  }
  .tool-drop {
    /* background-color: aliceblue; */
    margin-top: 100px;
  }
  .tool {
    /* flex-basis: 25%; */
    padding: 0.5rem;
  }

  body {
    padding: 0;
  }
  .hero-box {
    margin-top: 50px;
  }
  .tool-drop:hover,
  .tool-drop:active {
    border-style: none;
  }
  .quotes-block {
    margin-top: 100px;
  }
  .contact {
    margin-top: 100px;
  }
  .about-section,
  .about-sub {
    margin-top: 50px;
    margin-left: -1rem;
  }
}

@media only screen and (max-width: 620px) {
  
  .navbar-drop {
    display: none;
  }

  .mobile-nav-bar {
    display: block;
  }
  .about-sub-text {
    text-align: end;
  }
  .tool-drop,
  .tool-combo {
    margin-left: 0;
  }
  .cbse-logo {
    height: 6rem;
  }
  .iitm-logo {
    height: 5rem;
  }
  .qual{
    margin-left:-5rem;
  }
}

@media only screen and (max-width: 430px) {
  .mobile-nav-bar{
    margin-left:20px;
  }
  .tool-drop,
  .tool-combo {
    /* margin-left: -20px; */
    min-width: 100px;
  }
  .quotes-block{
    margin-left:0;
    margin-right: 0;
  }
  .right-quote-text{
    padding-left: 0;
  }
  .quotes-slide{
    min-width: 90vw;
  }
  .qual-year{
    display: none;
  }
  /* .footer, .about-section, .contact, .hero-box, .tool-drop, .tool-combo, .quotes-block{
    background-color: red;
  } */
}

.navbar-drop {
  /* background-image: url("https://petapixel.com/assets/uploads/2021/06/5tipslandscapeblackandwhite.jpg"); */
  margin: 0 2rem;
  border-radius: 10px;

  overflow: hidden;

}
.navbar {
  border-radius: 0.625rem;
  border-color: rgb(190, 179, 179);

  border-width: 0.1125rem;
  height: 4.5rem;
  display: flex;
  /* margin-top:1rem; */
  align-items: center;
  padding: 0 2rem;
  justify-content: space-between;
  backdrop-filter: blur(10px);
}

.navbar  h1 {
  background: linear-gradient(rgb(255, 214, 80), rgb(255, 251, 6));
  /* background: -webkit-linear-gradient(rgb(255, 106, 245), rgb(255, 57, 57)); */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  
  font-family: "Leckerli One";
  font-weight: 400;
  /* margin-left: -180px; */
}

.navbar h3{
  display:none;
  color:chartreuse;
  font-family: "Domine";
  font-weight: bold;
  font-size:200%
}

.navbar:hover h1{
  display: none;
}

.navbar:hover h3, .navbar:active h3{
  display: inline
}
.navbar > div > a,
.navbar > a {
  margin-left: 1.25rem;
  margin-right: 3.125rem;
  font-family: "Inter Tight";
  color: white;
  text-decoration: none;
}

.navbar:hover, .navbar:active{
  border-style:dotted;
  
}
.navbar a:hover, .navbar a:active{
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-line:  underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: chartreuse;
  filter: brightness(200%);
  font-size:150%;
}